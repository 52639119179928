<template>
  <layout name="NoColumns">
    <iframe
      id="trendsEmbedded"
      ref="trendsEmbedded"
      :src="iframeUrl"
      @load="iframeLoaded"
    />
  </layout>
</template>

<script>
import { loadScript, load } from '@hypefactors/shared/js/utils'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['currentUser']),

    iframeUrl () {
      const language = this.currentUser.locale || 'en'

      return process.env.VUE_APP_API_URL +
        'trends/iframe/?language=' + language +
        '&apiEndpoint=' + process.env.VUE_APP_API_URL + 'trends/'
    }
  },
  methods: {
    iframeLoaded () {
      // 1. we pass on the user token to the iframe
      const iframe = this.$refs.trendsEmbedded.contentWindow
      const token = load('access_token', '')
      iframe.postMessage({ token }, this.iframeUrl)
      // 2. we resize the iframe to make it fit inside the current app
      window.iFrameResizer = {}
      loadScript('https://cdn.jsdelivr.net/npm/@iframe-resizer/parent@5.3.2', 'iFrameResizer')
        .then(() => {
          window.iframeResize({
            license: 'GPLv3',
            waitForLoad: false
          }, '#trendsEmbedded')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#trendsEmbedded {
  width: 100%;
  height: 100vh;
}
</style>
